html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

@media (min-width: 1350px) {
  .container {
    max-width: 1320px;
  }
}

* {
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  letter-spacing: 0.3px;
}
/* .h-hr{
  border-top: 5px solid rgb(255, 0, 0);
  margin: 0;
} */
.Title {
  margin-bottom: 40px;
}

.Header {
  margin: auto;
  padding-top: 1px;
  padding-bottom: 1px;
  transition: 0.45s;
}
.Header .Justify-content-space-between {
  justify-content: space-between;
  background-color: rgb(255, 255, 255);
}
.w3-overlay {
  display: none;
}
.navbar-toggle {
  display: none;
}

.inverted {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 40;
  background: rgb(180, 18, 18);
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.2);
}

.header-menu {
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  width: 100%;
  padding: 0px;
  align-items: center;
  margin: 0 10%;
}

.nav-links a {
  color: black;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none;
}

.navbar {
  z-index: 50;
  background-color: white;
}

.Header .nav-menu {
  display: block;
  list-style: none;
  text-align: center;
  margin: 0 auto;
  padding: 0;
}

.Header .navbar-dark .navbar-nav .nav-link {
  color: rgb(0, 0, 0);
  font-size: 18px;
  font-weight: 700;
  margin-right: 10px;
  margin-left: 10px;
}
.Header .navbar-dark .navbar-nav .nav-link:hover {
  color: #FF6700;
}
.dropdown-menu {
  width: 18rem;
}

.navbar-expand-lg .navbar-nav .dropdown-menu {
  border-top: 5px solid #FF6700;
}

.dropdown-item a {
  color: black;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
  position: relative;
}

.nav-link a:hover {
  color: #FF6700;
}

.nav-item a:hover {
  color: #FF6700;
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: #1e2125;
  background-color: transparent;
}

.button-box {
  display: block;
}
.button-box i {
  font-size: 25px;
  display: flex;
  vertical-align: middle;
  font-weight: 700;
  margin: 0 auto;
  align-items: center;
}
.button-box a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.button-news{
  clear: both;
  background-color: #c20909;
  border: 0 none;
  border-radius: 4px;
  transition: all 0.23s ease-in-out 0s;
  color: #000000;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: normal;
  height: 32px;
  line-height: 32px;
  margin: 0 5px 10px 0;
  padding: 0 22px;
  text-align: center;
  text-decoration: none;
  vertical-align: top;
  white-space: nowrap;
  width: fit-content;
  width: -moz-fit-conte
}
a.m-a{
  color: #0E1AAF;
  padding: 0;
  
  margin: 0;
}
.news-bt{
  background-color: #ec671c;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  
}
.button-box h4 {
  font-size: 18px;
  display: inline-block;
  margin: 0;
  margin-left: 5px;
  vertical-align: middle;
}

.button-box {
  height: 48px;
  background-color: #FF6700;
  border: none;
  border-radius: 5px;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  transition: all 0.5s ease;
}

.button-box:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.button-box::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FF6700;
  border-radius: 5px;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.button-box:hover {
  background-color: #000;
  color: #fff;
}

.button-box .btn-primary:active {
  color: #fff;
  background-color: #000;
  border-color: none;
}

.button-box .btn-primary:active:focus {
  box-shadow: none;
}

.button-box .btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #000;
  border-color: none;
  box-shadow: none;
}

.button-box .btn:focus {
  outline: 0;
  box-shadow: none;
}

.hidden-lg {
  display: none;
}

/* toogle */
.Header button {
  color: #000;
  background-color: transparent;
  border: none;
}

.btn-navbar:focus-visible {
  outline: none;
}

/* button common  */

.btn-box-common {
  font-weight: 600;
  font-size: 18px;
  min-width: 170px;
  min-height: 48px;
  background-color: #FF6700;
  border: none;
  border-radius: 5px;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  transition: all 0.5s ease;
}

.btn-box-common:hover::before {
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.btn-box-common::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FF6700;
  border-radius: 5px;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.btn-box-common:hover {
  background-color: #0E1AAF;
  color: #fff;
}

.btn-box-common .btn-primary:active {
  color: #fff;
  background-color: #000;
  border-color: none;
}

.btn-box-common .btn-primary:active:focus {
  box-shadow: none;
}

.btn-box-common .btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #000;
  border-color: none;
  box-shadow: none;
}

.btn-box-common .btn:focus {
  outline: 0;
  box-shadow: none;
}

/* Title */

.Title-Head {
  text-align: center;
  max-width: 57%;
  margin: 0 auto;
  margin-bottom: 48px;
}

.Title-Head h6 {
  color: #fe4a55;
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
}

.Title-Head h2 {
  font-size: 45px;
  font-weight: 800;
}

.Title-Head p {
  font-size: 18px;
  font-weight: 600;
  color: #707070;
}

/* Bread Crumb */

.BreadCrumb {
  background-color: #f1f1f1;
}

.Bread-Crumb {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
}
.Bread-Crumb-partner {
  text-align: center;
  padding-top: 40px;
  padding-bottom: 20px;
}

.Bread-Crumb h1 {
  font-size: 30px;
  font-weight: 700;
  margin: 0 auto;
}
.Bread-Crumb h3 {
  font-size: 1.7rem;
  font-weight: 700;
  color: #FF6700;
  margin: 10px auto;
}
.Bread-Crumb-text a {
  display: inline-block;
  font-size: 17px;
  /* text-transform: capitalize; */
  margin-right: 15px;
  text-decoration: none;
}

.home-page {
  color: black;
  font-weight: 800;
}

.Current-page {
  color: #707070;
}

.Bread-Crumb-text a:hover {
  color: #ec671c;
  transition: 0.5s ease;
}

.Bread-Crumb-text h6 {
  display: inline-block;
  margin-right: 15px;
  margin-top: 10px;
  font-size: 20px;
}

/* Footer */

.Footer {
  background-color: #212529;
  padding-top: 10px;
}

.footer-text {
  color: #ffffff;
  line-height: 2.1;
}

.F-col-1 {
  padding-right: 30px;
}

.F-col-1 h2 {
  font-size: 28px;
  font-weight: 800;
  line-height: 1.9;
}

.F-col-1 p {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 30px;
}

.F-col-2 h2 {
  font-size: 20px;
  font-weight: 600;
  color: #f5f5f5;
  line-height: 1.9;
}

.F-col-3 h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.9;
  color: #f5f5f5;
}

.F-col-4 h2 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.9;
  color: #f5f5f5;
}

.F-col-2 ul {
  display: contents;
}

.F-col-2 li a {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.F-col-2 li a:hover {
  color: #FF6700;
  cursor: pointer;
  transition: 0.5s ease;
}

.F-col-3 li a {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.F-col-3 li a:hover {
  color: #FF6700;
  cursor: pointer;
  transition: 0.5s ease;
}

.F-col-4 li {
  margin-bottom: 5px;
}

.F-col-4 li h4 {
  font-size: 15px;
  font-weight: 600;
  display: contents;
  color: #ffffff;
}

.F-col-4 li span {
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
  /* margin-left: 10px; */
}

.Footerbox {
  padding-top: 50px;
}

.Footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #484545;
}

.Footer-bottom h6 {
  color: white;
  font-size: 15px;
}

.Footer-bottom h6 a {
  color: white;
  font-size: 15px;
  text-decoration: none;
}
.F-policy {
  display: inline-block;
  vertical-align: middle;
}

.F-copywrite {
  display: inline-block;
  vertical-align: middle;
}

.social-icon li a {
  width: 35px;
  background-color: white;
  text-align: center;
  margin-right: 15px;
  border-radius: 5px;
  text-decoration: none;
  color: black;
}

.social-icon li a:hover {
  background-color: #FF6700;
  color: #fff;
  transition: 0.3s ease;
}

.social-icon li a i {
  font-size: 18px;
  display: contents;
}

/* scroll-button */

.scroll-to-top {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  animation: fadeIn 700ms ease-in-out 1s both;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 10%);
  z-index: 1;
  border-radius: 50%;
}

.scroll-icon:hover {
  background-color: #fe4a55;

  box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 20%);
}

.scroll-icon {
  background-color: #fff;
  padding: 12px;
  color: #000;
  border-radius: 50%;
}

/* Responsive */

@media (min-width: 1200px) and (max-width: 1349px) {
  /* Comon Title */
  .Title-Head {
    max-width: 61%;
  }

  .Title-Head h6 {
    font-size: 14px;
  }

  .Title-Head h2 {
    font-size: 30px;
  }

  .Title-Head p {
    font-size: 14px;
  }

  /* header */
  .Header {
    margin: 0 5%;
  }

  .button-box h4 {
    font-size: 14px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* header */
  .Header {
    margin: 0 1%;
  }

  .nav-item {
    margin-left: 5px;
    margin-right: 5px;
  }

  .nav-links a {
    font-size: 14px;
  }

  .Header .navbar-dark .navbar-nav .nav-link {
    font-size: 14px;
  }

  .dropdown-item a {
    font-size: 14px;
  }

  .button-box h4 {
    font-size: 14px;
  }

  .button-box i {
    font-size: 14px;
  }

  /* Comon Title */
  .Title-Head {
    max-width: 70%;
    margin-bottom: 40px;
  }

  .Title-Head h6 {
    font-size: 16px;
  }

  .Title-Head h2 {
    font-size: 30px;
  }

  .Title-Head p {
    font-size: 16px;
  }

  /* Bread Crumb */
  .Bread-Crumb {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .Bread-Crumb-text a {
    font-size: 20px;
  }

  .Bread-Crumb h1 {
    font-size: 40px;
  }

  .Title {
    margin-bottom: 40px;
  }

  /* footer */
  .Footer {
    padding-top: 50px;
  }

  .Footerbox {
    padding-bottom: 20px;
  }

  .F-col-1 p {
    font-size: 15px;
    margin-bottom: 20px;
  }

  .F-col-2 h2 {
    font-size: 25px;
    line-height: 1.4;
  }

  .F-col-2 li a {
    font-size: 16px;
  }

  .F-col-3 h2 {
    font-size: 25px;
    line-height: 1.4;
  }

  .F-col-3 li a {
    font-size: 16px;
  }

  .F-col-4 h2 {
    font-size: 25px;
    line-height: 1.4;
  }

  .F-col-4 li {
    margin-bottom: 5px;
  }

  .F-col-4 li h4 {
    font-size: 16px;
  }

  .F-col-4 li span {
    font-size: 16px;
  }

  .Footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .Footer-bottom h6 {
    font-size: 16px;
  }
}
@media (min-width: 1200) {
  .hidden-lg {
    display: none !important;
  }
}
@media (max-width: 991px) {
  .Header .navbar-expand-lg .navbar-collapse {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 70px;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(238, 238, 238);
  }

  .navbar-toggle {
    display: block;
  }
  .Header {
    margin: 0 1%;
    padding: 0;
  }
  .button-box:hover {
    background-color: transparent;
  }

  .dropdown-toggle {
    position: relative;
  }

  .dropdown-toggle::after {
    position: absolute;
    right: 0;
  }

  .dropdown-menu {
    width: 100%;
  }

  .Header .navbar-dark .navbar-nav .nav-link {
    font-size: 16px;
  }

  .nav-links a {
    font-size: 16px;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    border: none;
  }

  .Header .navbar-dark .navbar-nav .nav-link a:hover {
    color: rgb(235, 7, 7);
  }
  Header .dropdown-item {
    padding-left: 25px;
  }
  .Header .dropdown-menu {
    padding: 0;
    margin: 0 auto;
  }

  .dropdown-item a {
    font-size: 14px;
  }

  .navbar-dark .navbar-nav .nav-link,
  .nav-link:focus {
    color: black;
    font-weight: 700;
  }
  .navbar-dark .navbar-nav .nav-link.active,
  .navbar-dark .navbar-nav .show > .nav-link {
    color: black;
  }
  .navbar-dark .navbar-nav .nav-link:focus {
    color: black;
    font-weight: 700;
  }
  .navbar-brand {
    margin-left: 53px;
  }

  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
  }
  .sidebar-overlay.active {
    opacity: 1;
    visibility: visible;
  }

  .sidenav .close-nav {
    background-color: #1e1e1e;
    padding: 12px 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    letter-spacing: 1px;
  }
  .sidenav .menu-responive {
    padding: 0 12px;
  }
  .close-nav a {
    color: #ffffff;
  }
  .Header:focus-visible {
    outline: none;
  }
  .Header .btn-primary:focus,
  .btn-primary:active,
  .btn-primary:hover {
    background-color: transparent;
    color: #000;
  }

  .Header button:focus {
    background-color: transparent;
    color: #000;
    box-shadow: none;
  }
  .btn-navbar.btn-primary {
    color: #000;
    background-color: transparent;
    border: none;
    position: absolute;
    margin: 0 auto;
  }
  .Header .btn-primary:active:focus {
    box-shadow: none;
  }
  .closebtn.btn-primary {
    background-color: transparent;
    color: white;
    border: none;
  }
  .closebtn.btn-primary:active:focus {
    box-shadow: none;
  }

  .navbar-toggle {
    position: relative;
    float: right;
    padding: 9px 10px;
    margin-top: 8px;
    margin-right: 15px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  .shadow-md {
    box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
  }
  .navbar {
    border-radius: 5px;
  }
  .sidebar {
    width: 280px;
    min-height: 100vh;
    box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
    background-color: #fff;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 1;
    transition: 0.5s;
  }
  .sidebar.active {
    left: 0;
  }
  .sd-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
  }
  .sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
  }
  .sidebar-overlay.active {
    opacity: 1;
    visibility: visible;
  }
  .sd-body {
    padding: 15px;
    max-height: calc(100vh - 67px);
    overflow-x: hidden;
  }
  .sd-body ul {
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }
  .sd-body ul li {
    list-style: none;
    margin-bottom: 8px;
  }
  .sd-body ul li .sd-link {
    display: inline-block;
    width: 100%;
    padding: 10px 16px;
    color: #475f7b;
    background-color: #e5e8ec;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: unset;
    width: 100%;
  }

  /* Comon Title */
  .Title-Head {
    max-width: 71%;
    margin-bottom: 35px;
  }

  .Title-Head h6 {
    font-size: 15px;
  }

  .Title-Head h2 {
    font-size: 30px;
  }

  .Title-Head p {
    font-size: 16px;
  }

  /* header */
  .nav-links a {
    font-size: 16px;
  }

  .button-box h4 {
    font-size: 16px;
  }

  .button-box i {
    font-size: 17px;
  }

  /* common -title-bottom  */
  .Title {
    margin-bottom: 75px;
  }

  /* common button */
  .btn-box-common {
    font-size: 14px;
    min-width: 116px;
    min-height: 23px;
  }

  /* Bread Crumb */
  .Bread-Crumb {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .Bread-Crumb h1 {
    font-size: 35px;
  }

  .Bread-Crumb-text a {
    font-size: 20px;
  }

  /* Footer */

  .F-col-1 {
    padding-right: 0;
  }

  .F-col-1 p {
    font-size: 14px;
  }

  .F-col-2 h2 {
    font-size: 20px;
  }

  .F-col-3 h2 {
    font-size: 20px;
  }

  .F-col-4 h2 {
    font-size: 20px;
  }

  .F-col-2 li a {
    font-size: 16px;
  }

  .F-col-3 li a {
    font-size: 16px;
  }

  .F-col-4 li h4 {
    font-size: 16px;
  }

  .F-col-4 li span {
    font-size: 14px;
  }

  .Footer-bottom h6 {
    font-size: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .container {
    max-width: unset;
    width: 100%;
  }
  /* header */

  .button-box h4 {
    font-size: 14px;
  }
  .button-box i {
    font-size: 18px;
  }

  /* Comon Title */
  .Title-Head {
    max-width: 81%;
    margin-bottom: 30px;
  }

  .Title-Head h6 {
    font-size: 14px;
  }

  .Title-Head h2 {
    font-size: 30px;
  }

  .Title-Head p {
    font-size: 15px;
  }

  /* common -title-bottom  */
  .Title {
    margin-bottom: 60px;
  }

  /* button */

  .btn-box-common {
    font-size: 12px;
    min-width: 85px;
    min-height: 19px;
  }

  /* Bread Crumb */
  .Bread-Crumb {
    padding-top: 50px;
    padding-bottom: 20px;
  }

  .Bread-Crumb h1 {
    font-size: 30px;
  }

  .Bread-Crumb-text h6 {
    margin-right: 10px;
    font-size: 20px;
  }

  .Bread-Crumb-text a {
    font-size: 18px;
    margin-right: 10px;
  }

  /* footer */
  .Footer {
    padding-top: 30px;
  }

  .F-col-1 p {
    font-size: 14px;
    margin-bottom: 20px;
  }

  .F-col-2 h2 {
    font-size: 15px;
    line-height: 1.2;
  }

  .F-col-3 h2 {
    font-size: 15px;
    line-height: 1.2;
  }

  .F-col-4 h2 {
    font-size: 15px;
    line-height: 1.2;
  }

  .F-col-2 li a {
    font-size: 13px;
  }

  .F-col-3 li a {
    font-size: 13px;
  }

  .F-col-4 li h4 {
    font-size: 13px;
  }

  .F-col-4 li span {
    font-size: 14px;
  }

  .Footerbox {
    padding-bottom: 20px;
  }

  .FooterVertical .btn::after {
    font-size: 12px;
  }

  .Footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .Footer-bottom h6 {
    font-size: 15px;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  /* header */

  .button-box h4 {
    display: none;
  }
  .button-box {
    background-color: transparent;
    height: auto;
  }
  .button-box::before {
    background-color: transparent;
  }
  .button-box i {
    color: #fe4a55;
  }

  /* Common button */
  .btn-box-common {
    font-size: 10px;
    min-width: 70px;
    min-height: 10px;
  }

  /* common -title-bottom  */
  .Title {
    margin-bottom: 30px;
  }

  .Title-Head {
    max-width: 100%;
    margin-bottom: 30px;
  }

  .Title-Head h6 {
    font-size: 12px;
  }

  .Title-Head h2 {
    font-size: 22px;
  }

  .Title-Head p {
    font-size: 13px;
  }

  /* Bread Crumb */
  .Bread-Crumb {
    padding-top: 30px;
    padding-bottom: 0px;
  }

  .Bread-Crumb h1 {
    font-size: 20px;
  }

  .Bread-Crumb-text h6 {
    margin-right: 5px;
    font-size: 13px;
  }

  .Bread-Crumb-text a {
    font-size: 13px;
    margin-right: 5px;
  }

  /* footer */

  .Footer {
    padding-top: 10px;
  }

  .F-col-1 {
    padding: 0;
  }

  .F-col-1 p {
    font-size: 13px;
    margin-bottom: 10px;
  }

  .F-col-2 h2 {
    font-size: 16px;
    line-height: 1.2;
  }

  .F-col-3 h2 {
    font-size: 16px;
    line-height: 1.2;
  }

  .F-col-4 h2 {
    font-size: 16px;
    line-height: 1.2;
  }

  .FooterVertical .btn::after {
    font-size: 10px;
  }

  .Footer-bottom {
    padding-top: 15px;
    padding-bottom: 15px;
    display: block;
  }

  .Footer-bottom h6 {
    font-size: 12px;
  }

  .social-icon li a {
    width: 29px;
    height: 29px;
  }

  .social-icon li a i {
    font-size: 14px;
    display: inline-block;
    vertical-align: text-bottom;
  }
  .F-col-2 li a {
    font-size: 12px;
  }
  .F-col-3 li a {
    font-size: 12px;
  }
  .F-col-5 li a {
    font-size: 12px;
  }
  .F-col-4 li h4 {
    font-size: 12px;
  }
  .F-col-4 li span {
    font-size: 11px;
  }
}

/* footer */
@media (min-width: 768px) {
  .FooterVertical .btn {
    display: none;
  }

  .FooterVertical .collapse {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .footerLinkHeading {
    position: relative;
  }

  .FooterVertical .btn {
    text-shadow: none;
    background: none;
    background-image: none;
    background-image: none;
    border: 0;
    box-shadow: none;
    font-size: 12px;
    position: absolute;
    top: -3px;
    right: 0;
    padding: 0;
    width: 100%;
  }

  .FooterVertical .btn::after {
    font-family: "FontAwesome";
    content: "\f067";
    font-size: 12px;
    font-weight: normal;
    float: right;
    cursor: pointer;
    color: white;
  }

  .FooterVertical .btn[aria-expanded="true"]::after {
    font-family: "FontAwesome";
    content: "\f068";
    font-size: 14px;
    font-weight: normal;
    float: right;
    cursor: pointer;
  }

  .btn-primary:active:focus {
    box-shadow: none;
  }
}
.Black-logo {
  width: auto;
  height: 80px;
  margin-right: 50px;
}
.White-logo {
  width: auto;
  height: 80px;
}
