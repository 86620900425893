.dark-c{
    // padding-left: 50px;
    padding-top: 70px;
    // padding-bottom: 15px;
    // padding-right: 50px;
    // margin-top: 50px;
  }
  .light-second{
    background-color: #ffffff;
    padding-left: 50px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-right: 50px;
  }
  
  .light-c h3{
    padding-left: 40px;
    margin-bottom: 30px;
    color: rgb(206, 24, 24);  ;
  }
  .light-c .head3{
    padding-left: 0;
  }
  .light-c h5{
    padding-top: 30px;
    padding-left: 40px;
    padding-bottom: 0;
  }
  .cbtn{
    margin-top: 20px;
    background-color: #FF6700;
    color: #FFFEFE;
    border: none;
    border-radius: 5px;
    transform: perspective(1px) translateZ(0);
    transition-property: color;
    transition-duration: 0.3s;
    transition: all 0.5s ease;
  
  }
  .cbtn:hover{
  background-color: #0E1AAF;
  color: #FFFEFE;
  }
  .light-c{
    padding-top: 50px;
    padding-left: 50px;
    padding-right: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    justify-content: center;
  }
  .light-card{
    padding-left: 50px;
    padding-right: 50px;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    justify-content: center;
  }
  .header-ap {
    font-family: "Google Sans",Helvetica,Arial,sans-serif;
    color: #000000;
    text-align: center;
  }
  .dark-gray{
    color: #0E1AAF;
    font-style: italic;
  }

  .header-ap h3{
    color: rgb(206, 24, 24);
  }
  div.col.creer{
    flex: 50%;
  }
  p.text-creer{
    padding-left: 40px;
    padding-right: 40px;
  }
  p.text-creer2{
    padding-left: 30px;
    padding-right: 40px;
  }
  .text-d{
    margin-top: 40px;
  }
  .col img {
    width: 100%;
    height: auto;
  }
  .col .logo-ap{
    width: 200px;
    height: auto;
  }
@media screen and (max-width: 992px) {
  .light-card {
    padding: 10px 15px;
  }

  p.text-creer{
    padding-left: 15px;
    padding-right: 15px;
  }
  p.text-creer2{
    padding-left: 15px;
    padding-right: 15px;
  }
}
  @media screen and (max-width: 600px) {
    .col-creer {
      flex: 100%;
    }
  }
  
  .col .logo-or{
    width: 150%;
    border-radius: 10%;
    padding: 0;
    margin-bottom:  40px;
    align-content: center;
  }
  .last{
    margin-bottom: 80px;
  }

  //other file
  .card-c {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #FFFEFE;
    background-clip: border-box;
    border: 2px solid #FF6700;
  }
  .card-c > hr {
    margin-right: 0;
    margin-left: 0;
  }
  
  .card-c > .list-group {
    border-top: inherit;
    border-bottom: inherit;
  }
  
  .card-c > .list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: calc(1.5rem - 1px);
    border-top-right-radius: calc(1.5rem - 1px);
  }
  
  .card-c > .list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: calc(1.5rem - 1px);
    border-bottom-left-radius: calc(1.5rem - 1px);
  }
  
  .card-c > .card-c-header + .list-group,
  .card-c > .list-group + .card-c-footer {
    border-top: 0;
  }
  
  .card-c-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem 1rem;
  }
  
  .card-c-title {
    margin-bottom: 0.1rem;
  }
  
  .card-c-subtitle {
    margin-top: -0.25rem;
    margin-bottom: 0;
  }
  
  .card-c-text:last-child {
    margin-bottom: 0;
  }
  
  .card-c-link:hover {
    text-decoration: none;
  }
  
  .card-c-link + .card-c-link {
    margin-left: 1rem;
  }
  
  .card-c-header {
    padding: 0.5rem 1rem;
    margin-bottom: 0;
    background-color: #f8f8f8;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-c-header:first-child {
    border-radius: calc(1.5rem - 1px) calc(1.5rem - 1px) 0 0;
  }
  
  .card-c-footer {
    padding: 0.5rem 1rem;
    background-color: #f8f8f8;
    border-top: 1px solid rgba(0, 0, 0, 0.125);
  }
  
  .card-c-footer:last-child {
    border-radius: 0 0 calc(1.5rem - 1px) calc(1.5rem - 1px);
  }
  
  .card-c-header-tabs {
    margin-right: -0.5rem;
    margin-bottom: -0.5rem;
    margin-left: -0.5rem;
    border-bottom: 0;
  }
  
  .card-c-header-tabs .nav-link.active {
    background-color: #FFFEFE;
    border-bottom-color: #FFFEFE;
  }
  
  .card-c-header-pills {
    margin-right: -0.5rem;
    margin-left: -0.5rem;
  }
  
  .card-c-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: calc(1.5rem - 1px);
  }
  
  .card-c-img,
  .card-c-img-top,
  .card-c-img-bottom {
    width: 100%;
  }
  
  .card-c-img,
  .card-c-img-top {
    border-top-left-radius: calc(1.5rem - 1px);
    border-top-right-radius: calc(1.5rem - 1px);
  }
  
  .card-c-img,
  .card-c-img-bottom {
    border-bottom-right-radius: calc(1.5rem - 1px);
    border-bottom-left-radius: calc(1.5rem - 1px);
  }
  
  .card-c-group > .card-c {
    margin-bottom: 1rem;
  }
  
  @media (min-width: 576px) {
    .card-c-group {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
    }
    .card-c-group > .card-c {
      -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
      flex: 1 0 0%;
      margin-bottom: 0;
    }
    .card-c-group > .card-c + .card-c {
      margin-left: 0;
      border-left: 0;
    }
    .card-c-group > .card-c:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .card-c-group > .card-c:not(:last-child) .card-c-img-top,
    .card-c-group > .card-c:not(:last-child) .card-c-header {
      border-top-right-radius: 0;
    }
    .card-c-group > .card-c:not(:last-child) .card-c-img-bottom,
    .card-c-group > .card-c:not(:last-child) .card-c-footer {
      border-bottom-right-radius: 0;
    }
    .card-c-group > .card-c:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
    .card-c-group > .card-c:not(:first-child) .card-c-img-top,
    .card-c-group > .card-c:not(:first-child) .card-c-header {
      border-top-left-radius: 0;
    }
    .card-c-group > .card-c:not(:first-child) .card-c-img-bottom,
    .card-c-group > .card-c:not(:first-child) .card-c-footer {
      border-bottom-left-radius: 0;
    }
  }

  .shadow-hover {   
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .shadow-hover:hover {
    box-shadow: 0 8px 8px -4px #FF6700;
  }
  .rounded-3 {
    border-radius: 2.25rem !important;
  }
  .text-center {
    text-align: center !important;
  }
  .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .card-c-body {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem 1rem;
  }
  .p-xxl-5 {
    padding: 0rem !important;
  }
  .light-card .p-10{
    margin-left: 10px;
  }

.col .Card-m{
  width: 8rem;
  border-radius: 5px;
  height: 12rem;
  text-overflow:clip;
  margin-right: 15px;
  margin-bottom: 15px;
  align-items: center;
  align-self: center;
  border: solid 1px #aca6a6;
  background-color: #f1f1f1;
  padding: 10px 10px 0 10px;
}
.col .Card-m:hover{
  transform: scale(1.1);
  background-color: #ffffff;
  box-shadow: 0 10px 20px rgba(161, 179, 181, 0.57), 0 6px 6px rgba(227, 158, 158, 0.23);
}
.Card-m-title{
  font-size: 15px;
  text-align: center;
  justify-items: end;
}
.col .Machines{
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  justify-content: center;
}