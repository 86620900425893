.agenda{
    margin-top: 40px;
    margin-bottom: 80px;
}

.container h3{
    font-weight: 1200;
    text-align: center;
    margin-bottom: 60px;
}

.agenda-card{
  flex: 25%;
}

p.agenda-card{
  padding-left: 0;
  padding-right: 0;
}
.agenda-img:hover  {
    box-shadow: 5px 5px 5px 5px #060606b4;
    -moz-box-shadow: 5px 10px 5px #000000b4;
   -webkit-box-shadow: 0px 1px 5px #0000006b
}
.card-col{
  height: auto;
  width: 250px;
}
.card-col:hover{
  box-shadow:#FF6700 (155, 13, 13, 0.542) 0px 2px 7px;
}
.fa-color-r{
    color:  #FF6700;
    margin-right: 20px;
}
.agenda-title{
   font-size : 17px;
    font-weight: 700;
    color: #FF6700;
}

.agenda-card.agenda-txt{
  padding-left: 0px;
  padding-right: 0px;
  font-size: 14px; 
}

.agenda-btn{
    color: black;
    font-size: 15px;
    font-weight: 700;
    text-decoration: none;
}
 .agenda-btn:hover{
  font-size: 15px;
    color: #FF6700;
    font-weight: 700;
 }
 .fa-plus{
    font-size: 8px;
 }

 .Page{
    margin-top: 50px;
    margin-bottom: 50px;
 }

 .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16em;
    height: 16em;
    transform: translate(-50%,-50%);
  }
  
  /* Chat */
  
  .chat {
    height: 7.75em;
    color: #12395F;
    overflow: hidden;
  }
  
  .chat > i {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 5.0625em;
    height: 2.625em;
    margin-left: -1.25em;
    transform: translate(-50%,-50%);
    background-color: #85C996;
    border: 0.25em solid;
    background-size: 1.25em;
    background-position: -0.0625em -0.1875em;
    border-radius: 0.5em;
    animation: slide1 2.43s infinite linear;
  }
  
  .chat > i:nth-child(even) {
    margin: 3.875em 0 0 1.1825em;
    transform: translate(-50%,-50%) scaleX(-1);
    background-color: #FFDEA3;
    animation: slide2 2.43s infinite linear;
  }
  
  .chat > i:nth-child(3) {
    margin-top: 7.75em;
  }
  
  .chat > i:nth-child(4) {
    margin: 11.625em 0 0 1.1825em;
  }
  
  .chat i i:first-child {
    position: absolute;
    top: 100%;
    left: 0.375em;
    width: 1.6875em;
    height: 1em;
    overflow: hidden;
  }
  
  .chat i i:first-child:before,
  .chat i i:first-child:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 200%;
    border-radius: 50% 50% 80%;
    box-shadow: inset -0.875em 0 currentColor;
  }
  
  .chat i i:first-child:after {
    right: 0.3125em;
    bottom: 0.25em;
    width: 80%;
    transform: rotate(-18deg);
    box-shadow: inset -0.375em 0 #85C996;
  }
  
  .chat i:nth-child(even) i:first-child:after {
    box-shadow: inset -0.375em 0 #FFDEA3;
  }
  
  .chat i i:last-child,
  .chat i i:last-child:before,
  .chat i i:last-child:after {
    content: '';
    position: absolute;
    top: 0.75em;
    left: 0.5625em;
    display: block;
    width: 80%;
    height: 0.125em;
    transform-origin: 0 0;
    background: currentColor;
  }
  
  .chat i:nth-child(3) i:last-child,
  .chat i:nth-child(3) i:last-child:before,
  .chat i:nth-child(3) i:last-child:after {
    animation: draw 2.43s 1.215s infinite;
  }
  
  .chat i:nth-child(4) i:last-child,
  .chat i:nth-child(4) i:last-child:before,
  .chat i:nth-child(4) i:last-child:after {
    transform-origin: 100% 0;
    animation: draw 2.43s infinite;
  }
  
  .chat i i:last-child:before,
  .chat i i:last-child:after {
    top: 0.5625em;
    left: 0;
    width: 100%;
    box-shadow: none;
  }
  
  .chat i:nth-child(3) i:last-child:before,
  .chat i:nth-child(3) i:last-child:after {
    animation-delay: 1.34s;
  }
  
  .chat i:nth-child(4) i:last-child:before {
    animation-delay: 0.125s;
  }
  
  .chat i i:last-child:after {
    top: 1.125em;
    width: 35%;
  }
  
  .chat i:nth-child(even) i:last-child:after {
    left: 2em;
    width: 50%;
  }
  
  .chat i:nth-child(3) i:last-child:after {
    animation-delay: 1.375s;
  }
  
  .chat i:nth-child(4) i:last-child:after {
    animation-delay: 0.25s;
  }
  
  @keyframes slide1 {
    0%, 5% { transform: translate(-50%,0); }
    18%, 52% { transform: translate(-50%,-124%); }
    65%, 100% { transform: translate(-50%,-248%); }
  }
  
  @keyframes slide2 {
    0%, 5% { transform: translate(-50%,-248%) scaleX(-1); }
    18%, 52% { transform: translate(-50%,-372%) scaleX(-1); }
    65%, 100% { transform: translate(-50%,-496%) scaleX(-1); }
  }
  
  @keyframes draw {
    0%, 20% { transform: scaleX(0); }
    27%, 100% { transform: scaleX(1); }
  }


  .interior a.agenda-btn:hover{
    font-weight: 800;
  }

/* inscre modal */
.modal-window {
  position: fixed;
  border: 20px;
  background-color: rgba(63, 63, 63, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
  &:target {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
  }
  & > div {
    width: 500px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 2em;
    background: white;
    color: black;
  }
  header {
    font-weight: bold;
  }
  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }
}

.modal-close {
  color: rgb(29, 29, 29);
  line-height: 50px;
  font-size: 80%;
  font-weight: 600;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 45px;
  text-decoration: none;
  &:hover {
    color: #FF6700;
    font-size: 100%;
  }
}
//modal responsive
@media screen and (max-width: 992px) {
  .modal-window {
    position: fixed;
    border: 20px;
    background-color: rgba(63, 63, 63, 0.25);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.3s;
    &:target {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
    & > div {
      width: 300px;
    }
    h1 {
      font-size: 110%;
    }
    p{
      font-size: 12px;
    }
  }  
}

/* Demo Styles */

a {
  color: inherit;
  text-decoration: none;
}

.modal-window {
  & > div {
    border-radius: 5px;
  }
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}

.logo {
  max-width: 150px;
  display: block;
}

small {
  color: rgb(144, 140, 140);
}
.inscris{
  color: #050d30;
  font-weight: 600;
}
.inscris:hover{
  color: #FF6700;
}

.modal-input{
  font-size: 14px;
  border-radius: 6px;
  line-height: 1.5;
  padding: 5px 10px;
  transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
  border: 1px solid #e0dcdc;
  color: rgb(14, 14, 16);
  background: #ffffff;
  display: block;
  height: 36px;
  width: 100%;  
}
.modal-input:hover {
  border-color: #FF6700;
}
.modal-input:focus{
  border: 0.1px #e0dcdc;
  border-color: #ffa347;
  background: #fff;
}
.small-note{
  color: #FF6700;
  font-size: 14px;
}
.page-link{
  color:#FF6700;
}

@media screen and (max-width: 992px) {
  .card-col{
    height: auto;
    width: 100%;
}
}