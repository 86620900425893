.news{
  margin-top: 40px;
  margin-bottom: 80px;
}

.container h3{
  
  font-weight: 1200;
  text-align: center;
  margin-bottom: 30px;
}

.text-p{
  margin-bottom: 60px;
  font-size: 16px;
  font-weight: 500;
  color:rgba(29, 29, 29, 0.915);
  text-align: center;
}
.news-img:hover  {
  box-shadow: 5px 5px 5px 5px #060606b4;
  -moz-box-shadow: 5px 10px 5px #000000b4;
 -webkit-box-shadow: 0px 1px 5px #0000006b
}

.fa-color-r{
  color:  #FF6700;
  margin-right: 20px;
}
.news-title{
 font-size : 17px;
  font-weight: 700;
  color: #FF6700;
}

.news-txt{
  font-size: 15px; 
}

.news-btn{
  color: black;
  font-size: 15px;
  font-weight: 700;
  text-decoration: none;
}
.news-btn:hover{
  color: #FF6700;
  font-weight: 800;
}
.fa-plus{
  font-size: 8px;
}

.Page{
  margin-top: 50px;
  margin-bottom: 50px;
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16em;
  height: 16em;
  transform: translate(-50%,-50%);
}

/* Chat */
@keyframes slide1 {
  0%, 5% { transform: translate(-50%,0); }
  18%, 52% { transform: translate(-50%,-124%); }
  65%, 100% { transform: translate(-50%,-248%); }
}

@keyframes slide2 {
  0%, 5% { transform: translate(-50%,-248%) scaleX(-1); }
  18%, 52% { transform: translate(-50%,-372%) scaleX(-1); }
  65%, 100% { transform: translate(-50%,-496%) scaleX(-1); }
}

@keyframes draw {
  0%, 20% { transform: scaleX(0); }
  27%, 100% { transform: scaleX(1); }
}

.button-ins {
  font-size: 15px;
  padding: 5px;
  color: rgb(255, 255, 255);
  background-color: #FF6700;
  border-radius: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.8s ease-out;
  // bottom: 0;
  // position: absolute;
}
.button-ins:hover {
  background: #0E1AAF;
  color: #fff;
}

.card-col{
  height: 200px;
}

.card-col:hover{
 box-shadow: #FF6700 0px 2px 7px;
}
.figcaption {
  width: 100%;
  background-color: #ffffff;
  position: relative;
}
.figcaption:before {
  position: absolute;
  content: '';
  z-index: 2;
  bottom: 100%;
  left: 0;
  width: 100%;
  height: 30%;
  background-image: linear-gradient(to bottom, transparent 0%, #ffffff 100%);
}
.figcaption .news-title{
  margin-top: 20px;
}
.logo {
max-width: 150px;
display: block;
}

small {
color: rgb(144, 140, 140);
}
.inscris{
color: #050d30;
font-weight: 600;
}
.inscris:hover{
color: #FF6700;
}

.modal-input{
font-size: 14px;
border-radius: 6px;
line-height: 1.5;
padding: 5px 10px;
transition: box-shadow 100ms ease-in, border 100ms ease-in, background-color 100ms ease-in;
border: 1px solid #e0dcdc;
color: rgb(14, 14, 16);
background: #ffffff;
display: block;
height: 36px;
width: 100%;  
}
.modal-input:hover {
border-color: #FF6700;
}
.modal-input:focus{
border: 0.1px #e0dcdc;
border-color: #ffa347;
background: #fff;
}
.small-note{
color: #FF6700;
font-size: 14px;
}
.page-link{
color:#FF6700;
font-weight: 700;
}
.acut-pic{
  margin: 0;
  height: auto;
  width: 100%;
}
.acut-h2{
  margin: 0;
  background-color: #e84f03;
  padding: 20px;
  color: #fff;
  text-align: center;
  font-weight: 800;
}
.container .acut-h3{
  font-size: 27px;
  align-content: left;
  color: #FF6700;
  margin: auto;
  font-weight: 800;
  padding-top: 50px;
  padding-bottom: 20px;
}
.acut-h4{
  font-size: 20px;
  align-content: left;
  color: #0E1AAF;
  border-radius:10px ;
  margin: auto;
  font-weight: 800;
  padding-bottom: 5px;
}
.background{
  height: 15px;
  background-color: #0E1AAF;
}
.ex-video{
  height: 200px;
}

.youtube-if{
  width: 560px;
  height: 315px;
  border-radius: 10px;
}
@media screen and (max-width: 600px) {
  .youtube-if{
    width: 300px;
    height: 160px;
    border-radius: 10px;
  }
}

.line-break {
  white-space: pre-wrap;
}