.dark,
.dark-third {
  background-color: #f1f1f1;
  padding: 60px 60px;
}

a:hover {
  color: #FF6700;
}

.light h3 {
  padding-left: 40px;
  margin-bottom: 30px;
  color: #FF6700;
}
.light .head3 {
  padding-left: 0;
}
.light h5 {
  padding-left: 40px;
  padding-bottom: 0;
}
.light {
  padding-top: 50px;
  padding-left: 50px;
  padding-right: 50px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  justify-content: center;
}
.header-ap {
  font-family: "Google Sans", Helvetica, Arial, sans-serif;
  color: #000000;
  text-align: center;
}
.dark-gray {
  color: #0E1AAF;
  font-style: italic;
}
.content-ap {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  box-shadow: 0 3px 7px rgba(146, 145, 145, 0.478),
    0 0px 0px rgba(142, 48, 48, 0.23);
  background-color: #f1f1f1;
}
br.mt-4{
  margin-top: 40px;
}
.content-ap2 {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  /* justify-content: center; */
}
.header-ap h3 {
  color: #FF6700;
}
div.col.apprendre {
  flex: 50%;
}
p.paragraph-app {
  padding-left: 40px;
  padding-right: 40px;
}
div.paragraph-app {
  padding-left: 40px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.text-d {
  margin-top: 40px;
}
.col img {
  width: 100%;
  height: auto;
}
.col .logo-ap {
  width: 200px;
  height: auto;
}
.paragraph-app div {
  width: 50px;
  height: 50px;
  margin: 10px;
}
@media screen and (max-width: 992px) {
  p.paragraph-app {
    padding-left: 15px;
    padding-right: 15px;
  }

  div.paragraph-app {
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .dark {
    background-color: #f1f1f1;
    padding: 30px 15px;
  }
  .dark-third {
    background-color: #f1f1f1;
    padding: 30px 15px;
  }
}
@media screen and (max-width: 600px) {
  .col {
    flex: 100%;
  }
}

.col .logo-log {
  width: 60%;
  padding: 0;
  margin-bottom: 20px;
  align-content: center;
}

.last {
  margin-bottom: 80px;
}
/* Gallary */

.Career-gallary {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  background: #0E1AAF;
  padding: 15px;
}
.Career-gallary img {
  width: 100%;
  display: block;
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
  transition: all 100ms ease-out;
}
.Career-gallary img:hover {
  transform: scale(1.04);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}
@media screen and (max-width: 992px){
  .Career-gallary img {
    transform: scale(1);
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
  }
}

h3.h3 {
  color: #FF6700;
  padding-bottom: 20px;
  padding-top: 50px;
  text-align: left;
  margin: auto;
  justify-content: left;
}
.row-app {
  padding-bottom: 50px;
}
.row-app2 {
  padding-bottom: 50px;
}

.col-bg {
  background-color: rgb(230, 230, 230);
}

.content-ap2 h4{
color: #0E1AAF;
}
.row-app h5{
color: #0E1AAF;
}

@media screen and (max-width: 600px){
  .col-md-6 {
    padding-bottom: 20px;  }
}


@media screen and (max-width: 600px){
  .col {
    padding-bottom: 30px;
    }
}