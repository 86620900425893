
/*  */
.section-container {
    padding: 80px 0
}
.section-half-background-image-container1 {
    position: relative;
    background-color: rgb(61, 61, 61);
    padding: 0px 0 0px
}

.section-half-background-image-container1 .image-column-r {
    background-image: url('../../../src/assets/Recycler/img9.png');
    display: none;
}

@media screen and (min-width:992px) {
    .section-half-background-image-container1 .image-column-r {
        display: block;
        top: 0;
        z-index: 0;
        bottom: 0;
        /* margin-top:80px;
        margin-right: 50px;
        margin-bottom: 80px;
        width: 50%; */
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top
    }
}
@media (min-width:992px) {
    .section-half-background-image-container1 .text-column {
        margin-top:10px;
        margin-right: 100px;
        margin-bottom: 80px;
        position: relative;
        z-index: 3;
    }
}

@media screen and (max-width: 992px){
    .section-half-background-image-container1 .col-md-6 {
        padding-top: 10px;
        padding-right: 50px;
        padding-left: 50px;
        padding-bottom: 50px;
        width: 100%;
        position: relative;
        z-index: 3;
    }
}
@media screen and (max-width: 500px){
    .section-half-background-image-container1 .col-md-6 {
        padding-top: 10px;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 30px;
        width: 100%;
        position: relative;
        z-index: 3;
    }
}
@media screen {
    .section-half-background-image-container1 .text-column p {
    color: rgb(255, 255, 255);
    padding:0 ;
    }
}
.section-half-background-image-container1 .text-column {
    margin-top:80px;
    margin-right: 50px;
    margin-bottom: 80px;
    position: relative;
    z-index: 3;
}
.section-half-background-image-container1 .text-column h2:after {
    content: "";
    display: block;
    background-color: rgb(255, 255, 255);
    height: 3px;
    margin: 20px 0;
    width: 180px;
}
.section-half-background-image-container1 .text-column h2 {
    text-align: left;
    color: #FF6700;
    color: rgb(255, 255, 255);
}
.section-half-background-image-container1 .text-column h3 {
    text-align: left;
    color: #fff;
}
.section-half-background-image-container1 .text-column p 
{
    color: rgb(255, 255, 255);
}
.section-half-background-image-container1 .text-column p a {
    color: rgb(255, 255, 255);
    font-weight: 500;
}
.section-half-background-image-container1 .text-column p a:hover {
 font-weight: 800;
 
}
.section-label {
    display: none
}

.section-container-spacer {
    margin-bottom: 30px
}

.col-md-offset-6 {
    margin-left: 50%
}

.container {
    margin-right: auto;
    margin-left: auto;
}

.container:after,
.container:before {
    content: " ";
    display: table
}

.container:after {
    clear: both
}

@media (min-width:768px) {
    .container {
        width: 750px
    }
}

@media (min-width:992px) {
    .container {
        width: 970px
    }
}

@media (min-width:1200px) {
    .container {
        width: 1170px
    }
}

.container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px
}

.container-fluid:after,
.container-fluid:before {
    content: " ";
    display: table
}

.container-fluid:after {
    clear: both
}