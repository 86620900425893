.bloc-tabs {
  border-radius: 10px;
  margin-bottom: 40px;
}

.tabs {
  padding: 20px;
  text-align: center;
  box-shadow: 0 0 10px #cac7c7;
  background: transparent;
  cursor: pointer;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.274); */
  /* box-sizing: content-box; */
  position: relative;
  outline: none;
  font-size: 20px;
  border-radius: 5px;
}

.bloc-tabs ul{
  justify-content: center;
} 

.tab-menu li {
  margin-left: 10px;
  margin-right: 10px;
}

.tabs:hover {
  background-color: #FE4A55;
  color: white;
  transition: 0.5s ease;
}

.active-tabs {
  background: #FE4A55;
  color: white;
}

/* .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(60% + 2px);
    height: 2px;
    background: #FE4A55;
    border-radius: 50px;
  } */

button {
  border: none;
}

.content-tabs {
  flex-grow: 1;
}

.content {
  background: white;
  padding: 20px 50px 0px 50px;
  width: 100%;
  height: 100%;
  display: none;
}

/* .content h2 {
      padding: 0px 0 5px 0px;
    } */
/*  */
/* .content {
    width: 100%;
    height: 100%;
    line-height: 2.6;
    font-size: 20px;
  } */
.active-content {
  display: block;
  /* padding-right: 0;
    padding-left: 0; */
}

.content .accordion-item {
  box-shadow: 2px 8px 20px 0 rgb(25 42 70 / 13%);
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
}

.content .accordion-button {
  font-size: 18px;
  font-weight: 700;
  color: black;
  background-color: transparent;
  box-shadow: none;
}

.content .accordion-body {
  font-size: 16px;
  color: #707070;
  font-weight: 500;
}

/* Responsive */

@media (min-width: 992px) and (max-width: 1199px) {
  .tabs {
    padding: 14px;
    font-size: 18px;
  }

  .content .accordion-button {
    font-size: 17px;
  }

  .content .accordion-body {
    font-size: 15px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .bloc-tabs {
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .tabs {
    padding: 8px;
    font-size: 16px;
  }

  .content .accordion-button {
    font-size: 16px;
  }

  .content .accordion-body {
    font-size: 15px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .bloc-tabs ul {
    justify-content: normal;
  }
  .bloc-tabs {
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .tabs {
    padding: 8px;
    font-size: 16px;
  }

  .content .accordion-button {
    font-size: 16px;
  }

  .content .accordion-body {
    font-size: 15px;
  }
  .tab-menu li{
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .content {
    padding: 0;
  }
 
}

@media (min-width: 320px) and (max-width: 575px) {
  .bloc-tabs ul {
    justify-content: center;
  }
  .bloc-tabs {
    border-radius: 10px;
    margin-bottom: 10px;
  }

  .tabs {
    padding: 8px;
    font-size: 13px;
  }

  .content .accordion-button {
    font-size: 14px;
    padding: 14px;
  }

  .content .accordion-body {
    font-size: 13px;

  }
  .tab-menu li {
    margin-bottom: 10px;
  }
  .content {
    padding: 0;
  }
}