.TermsCImg{
    text-align: center;
    margin-bottom: 80px;
}
.term-photo{
    border-radius: 8px;
}
.TermsContent{
    margin-bottom: 30px;
}
.j-p{
    text-align: left;    
}
.TermsContent h4{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    position: relative;
}
.Title h3{
    font-weight: 1200;
    text-align: center;
    margin-bottom: 40px;
    margin-top: 40px;
}
 .number{
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
    position: relative;
}
.fa-color-f{
    color:  rgb(226, 21, 21);
    margin-right: 20px;
}
.TermsContent ol{
    color: #2d2c2c;
    font-size: 16px;
    font-weight: 600;
    margin: 0 auto;
    margin-bottom: 5px;
    margin-left: 30px;
}
/* .TermsContent h4::after{
    position: absolute;
    content: "";
    border-bottom: dashed 1px #FE4A55;
    top: 0;
    bottom: 0;
    width: 35%;
    left: 0;
} */
blockquote{
    background-color: #fafafa;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 12px;
    padding-right: 12px;
    margin-bottom: 0;
}
blockquote p{
    margin: 0 auto !important;
    opacity: 1.9;
}
.TermsContent p{
    color: #2d2c2c;
    font-size: 16px;
    font-weight: 600;
    margin: 0 auto;
    margin-bottom: 10px;
    margin-left: 30px;
}
 .TermsContent a{
    color: #FF6710;
    font-weight: 600;
    text-decoration: none;
 }
 .bold-text{
    color: #FF6710;
    font-weight: 600; 
 }
.TermsContent li{
    color: #2d2c2c;
    font-size: 16px;
    font-weight: 600;
    margin: 0 auto;
    margin-bottom: 0;
}

/* Responsive */

@media (min-width: 1200px) and (max-width: 1349px) {
    .TermsCImg {
        margin-bottom: 40px;
    }

}   

@media (min-width: 992px) and (max-width: 1199px) {

    .TermsCImg {
        margin-bottom: 40px;
    }
    .TermsContent h4 {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .TermsContent p {
        font-size: 16px;
    }
    .TermsContent li {
        font-size: 16px;
    }
    .TermsContent {
        margin-bottom: 40px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .TermsCImg {
        margin-bottom: 40px;
    }
    .TermsContent h4 {
        font-size: 21px;
        margin-bottom: 15px;
    }
    .TermsContent p {
        font-size: 15px;
    }
    .TermsContent li {
        font-size: 15px;
    }
    .TermsContent {
        margin-bottom: 40px;
    }

}
@media (min-width: 576px) and (max-width: 767px) {
    .TermsCImg {
        margin-bottom: 35px;
    }
    .TermsContent h4 {
        font-size: 20px;
        margin-bottom: 15px;
    }
    .TermsContent p {
        font-size: 15px;
    }
    .TermsContent li {
        font-size: 15px;
    }

    .TermsContent {
        margin-bottom: 35px;
    }
}

@media (min-width: 320px) and (max-width: 575px) {
    .TermsCImg {
        margin-bottom: 25px;
    }
    .TermsContent h4 {
        font-size: 18px;
        margin-bottom: 12px;
    }
    .TermsContent p {
        font-size: 14px;
        margin-left: 0;
    }
    .TermsContent li {
        font-size: 14px;
    }
    .TermsContent {
        margin-bottom: 25px;
    }
}
.bpd-text{
    color: #FF6710;
    font-weight: 600;
}