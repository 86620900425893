@import url(https://fonts.googleapis.com/css?family=Istok+Web);
@keyframes slidy {
  0% {left:0%;}
  10% {left:0%; }
  20% {left:-100%; }
  30% {left:-100%; }
  40% {left:-200%; }
  50% {left:-200%; }
  60% {left:-300%; }
  70% {left:-300%; }
  80% {left:-400%; }
  90% {left:-400%; }
  100% {left:-400%;}
}

* {
  box-sizing: border-box;
}

div#captioned-gallery { 
  width: 100%;
  overflow: hidden; 
}

figure.slider { 
  position: relative;
  width: 500%;
  font-size: 0;
  animation: 20s slidy infinite; 
}
figure.slider figure { 
  width: 20%;
  height: auto;
  display: inline-block;
  position: inherit; 
}
figure.slider img {
  width: 100%;
  height: auto;
}

figure.slider figure figcaption { 
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.207);
  color: #fff;
  width: 100%;
  font-size: 2rem;
  padding: .6rem; 
}