.team-info{
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .button-equipe{
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    text-decoration:none;
    font-size: 17px;
  }
  .button-equipe::after{
    color: #FF6700;
    font-weight: 700;
  }


  .our-team {
    padding: 20px 0 20px;
    margin-bottom: 50px;
    background-color: #7a79792a;
    text-align: center;
    overflow: hidden;
    position: relative;
    border-radius: 40px;
  }
  
 .picture {
    display: inline-block;
    height: 130px;
    width: 130px;
    margin-bottom: 10px;
    z-index: 1;
    position: relative;
  }
   .picture::before {
    content: "";
    width: 100%;
    height: 0;
    border-radius: 50%;
    background-color: #FF6700;
    position: absolute;
    bottom: 135%;
    right: 0;
    left: 0;
    opacity: 0.9;
    transform: scale(3);
    transition: all 0.3s linear 0s;
  }
  .our-team:hover .picture::before {
    height: 100%;
  }
  .our-team .picture::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #60606039;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  
  .our-team .picture img {
    width: 100%;
    height: auto;
    border-radius: 50%;
    transform: scale(1);
    transition: all 0.9s ease 0s;
  }
  
  .our-team:hover .picture img {
    box-shadow: 0 0 0 14px #f7f5ec;
    transform: scale(0.7);
  } 
  
  .fname {
    display: block;
    font-size: 17px;
    font-weight: 700;
    color:#0E1AAF;
    margin-bottom: 10px;
    /* text-transform: capitalize; */
  }
  .job {
    display: block;
    font-size: 15px;
    font-weight: 700;
    color: #191919;
    margin-bottom: 35px;
  }

  .our-team .social {
    align-items:center;
    width: 100%;
    padding-top: 10px;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    margin: 0;
    background-color: #FF6700;
    position: absolute;
    bottom: -100px;
    left: 0;
    transition: all 0.5s ease 0s;
  }

  
  .our-team:hover .social {
    bottom:0;
  }
  .our-team .social li {
    display: inline-block;
  }
  
  .our-team .social li a {
    display: block;
    padding-right: 10px;
    font-size: 17px;
    color: white;
    z-index: 2;
    transition: all 0.3s ease 0s;
    text-decoration: none;
  }
  .our-team .social li p {
    font-family: 'Nunito', sans-serif;
    color: white;
    margin-left: 10px;
    font-size: 15px;
    font-weight: 700;
    padding: 0;
  }
  .our-team .social li a:hover {
    color: #000000;
  }
  
  
.wrapper{
  width: 100%;
} 

.wavefull{
  width:auto;
  /*change height of the div*/
  color:#000000;
}
.wavefull h3{
    color: rgb(255, 255, 255);
    margin-top: 40px;
    margin-bottom: 130px;
    text-align: center;
}

.fa-color-r{
  color:  #FF6700;
  margin-right: 20px;
}
.herobg {
    position: relative;
    overflow: hidden;
    background: #FF6700; /*change color of the div*/
}

.wave {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: url(https://i.postimg.cc/XqYkMxZ1/3.png);
    background-size: 1000px 100px;
}
/*Change speed of animation*/
.wave.wave1 {
    animation: animate 30s linear infinite;
    z-index: 1000;
    opacity: 1;
    animation-delay: 0s;
    bottom: 0;
}

.wave.wave2 {
    animation: animate2 15s linear infinite;
    z-index: 999;
    opacity: 0.5;
    animation-delay: -5s;
    bottom: 10px;
}

.wave.wave3 {
    animation: animate 30s linear infinite;
    z-index: 99 8;
    opacity: 0.2;
    animation-delay: -2s;
    bottom: 15px;
}

.wave.wave4 {
    animation: animate2 5s linear infinite;
    z-index: 997;
    opacity: 0.7;
    animation-delay: -5s;
    bottom: 20px;
}

@keyframes animate {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 1000px;
    }
}

@keyframes animate2 {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: -1000px;
    }
}