.section-container {
  padding: 80px 0;
}
.section-half-background-image-container {
  position: relative;
  background-color: rgb(61, 61, 61);
  padding: 80px 0 100px;
}

.section-half-background-image-container .image-column {
  background-image: url("../../../src/assets/Former/img-01.png");
  display: none;
}

@media (min-width: 992px) {
  .section-half-background-image-container .image-column {
    position: absolute;
    display: block;
    top: 0;
    z-index: 0;
    bottom: 0;
    width: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
  }
}

.section-half-background-image-container .text-column {
  padding: 50px !important;
  position: relative;
  z-index: 3;
}
.section-half-background-image-container .text-column h2:after {
  content: "";
  display: block;
  background-color: rgb(255, 255, 255);
  height: 3px;
  margin: 20px 0;
  width: 150px;
}
.section-half-background-image-container .text-column h2 {
  text-align: left;
  color: rgb(255, 255, 255);
}
.section-half-background-image-container .text-column h3 {
  text-align: left;
  color: #fff;
}
.section-half-background-image-container .text-column p {
  color: rgb(255, 255, 255);
}
.section-half-background-image-container .text-column p a {
  color: rgb(255, 255, 255);
  font-weight: 500;
}
.section-half-background-image-container .text-column p a:hover {
  font-weight: 800;
}
.section-label {
  display: none;
}

@media (min-width: 992px) {
  .section-label {
    display: flex;
    color: #fff;
    font-size: 7em;
    font-family: Abril Fatface, Times New Roman, Times, serif;
    position: absolute;
    left: 50%;
    z-index: 2;
    top: 0;
    height: 100%;
    text-transform: uppercase;
    align-items: center;
  }

  .section-label p {
    width: 260px;
    margin-left: -130px;
    word-break: break-all;
  }
}

.section-container-spacer {
  margin-bottom: 30px;
}

.col-md-offset-6 {
  margin-left: 50%;
}

.container {
  margin-right: auto;
  margin-left: auto;
}

.container:after,
.container:before {
  content: " ";
  display: table;
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
  .text-column {
    padding: 15px !important;
    padding-left: 30px !important;
  }
}
@media (max-width: 992px) {
  .section-half-background-image-container .text-column {
    padding: 15px !important;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.container-fluid:after,
.container-fluid:before {
  content: " ";
  display: table;
}

.container-fluid:after {
  clear: both;
}
