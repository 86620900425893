
.Blog-card{
    margin-bottom: 30px;
    cursor: pointer;
}
.r-card{
    border: 0;
}
.Blog-card .card-title{
    font-size: 22px;
    font-weight: 800;
}
.Blog-card p{
    font-size: 16px;
    font-weight: 500;
    color: #555353;
}
.Blog-img{
    margin: 10px;
}

.Blog-icon-box{
position: absolute;
left: 40px;
right: 0;
text-align: center;
top: 45%;
}

/* Responive */

@media (min-width: 992px) and (max-width: 1199px) {
    .Blog-card .card-title {
        font-size: 20px;
    }
    .Blog-card p {
        font-size: 15px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    
    .Blog-card .card-title {
        font-size: 20px;
    }
    .Blog-card p {
        font-size: 15px;
    }
    
}
@media (min-width: 576px) and (max-width: 767px) {
   
    .Blog-card .card-title {
        font-size: 17px;
    }
    .Blog-card p {
        font-size: 13px;
    }

}  
@media (min-width: 320px) and (max-width: 575px) {
    .Blog-card .card-title {
        font-size: 16px;
    }
    .Blog-card p {
        font-size: 12px;
    }
  }
  .fa-color-r{
    color:  rgb(203, 12, 12);
    margin-right: 20px;
    text-align: center;
}
.center{
    text-align: center;
    margin-bottom: 60px;
    margin-top: 40px;
}

.Blog-card p .paragraph-r{
    font-size: 15.5px;
    margin-top: 30px;
    font-weight: 500;
    color: #000000;
}
.Blog-card hr.hr-tag{
    border: 0;
    height: 10px;
    border-radius: 5px;
    background-image: linear-gradient(to right,rgb(6, 10, 240) , rgb(255, 0, 0), rgb(255, 255, 255));
}
.blog-a:hover{
    color:#0E1AAF;    
}
.blog-a{
    color: #FF6700;
    font-weight: 600;
    text-decoration: underline;
}