
.fa-handshake{
    margin-top: 50px;
}
.center-p{
    text-align: center;
}
.image-d{
    margin-bottom: 50px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.image{
    text-align: center;
}


/*  */
.logo-container {
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    }
  
  .logo-container img {
    width: 120px;
    object-fit:contain;
    height: auto;
    align-items: center;
    display: inline;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  